<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h4>POLÍTICA DE PRIVACIDADE</h4>
          <br />
          <br />
          <h4>CONTROLADORAS DOS DADOS PESSOAIS:</h4>
          <br />
          <p>
            WEBZI PESQUISA & INOVAÇÃO TECNOLOGICA LTDA., com sede na Cidade do
            Rio de Janeiro, Estado do Rio de Janeiro, na Avenida Adalgisa
            Colombo, 135 – Loja 2067 - CEP: 22775-026, inscrita no CNPJ/MF:
            37.552.169/0001-64 (“WEBZI”) é a empresa que presta serviços de
            intermediação da venda de bens no Site EBL Online e realiza a
            promoção dos leilões e outros eventos;
          </p>
          <p>
            A WEBZI está comprometida em garantir a privacidade e a proteção dos
            dados pessoais dos usuários do Site EBL Online
            [<a href="https://www.eblonline.com.br">https://www.eblonline.com.br</a>], nos termos do que estabelece a Lei
            Geral de Proteção de Dados Pessoais nº 13.709/2018 (“LGPD”). Diante
            disso, em respeito ao dever de transparência, o empresa torna
            público este Aviso de Privacidade que descreve como utilizamos,
            armazenamos e compartilhamos os dados pessoais obtidos por meio do
            Site EBL Online.
          </p>
          <p>
            Além disso, nossos Termos de Uso explicam em detalhes os serviços
            prestados pelo Site EBL Online, podendo ser acessados em:
            <a href="https://www.eblonline.com.br/termos-de-uso">https://www.eblonline.com.br/termos-de-uso</a> antes de explicarmos
            melhor como utilizamos os dados pessoais coletados no Site EBL
            Online, informamos abaixo alguns conceitos importantes para que Você
            possa entender melhor nosso Aviso de Privacidade:
          </p>
          <p>
            <b>Site EBL Online:</b> plataforma tecnológica de serviços
            interativos de leilões e vendas online de bens móveis e imóveis e de
            direitos a eles relativos.
          </p>
          <p>
            <b>Dado Pessoal: </b>toda e qualquer informação relacionada à pessoa
            natural (física) identificada ou identificável, ou seja, o conceito
            abrange informações diretas, como nome, RG, CPF e endereço, bem como
            indiretas, como dados de localização e demais identificadores
            eletrônicos.
          </p>
          <p>
            <b>Controlador dos Dados Pessoais:</b> Pessoa natural (física) ou
            jurídica, de direito público ou privado, a quem competem as decisões
            referentes ao tratamento de dados pessoais.
          </p>
          <p>
            <b>Operador dos Dados Pessoais:</b> Pessoa natural (física) ou
            jurídica, de direito público ou privado, que realiza o tratamento de
            dados pessoais em nome do controlador.
          </p>
          <p>
            <b>Tratamento de Dados Pessoais:</b> Toda operação realizada com
            dados pessoais, como as que se referem a coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle de informação, modificação,
            comunicação, transferência, difusão ou extração.
          </p>
          <p>
            <b>Titular dos Dados Pessoais:</b> Pessoa natural (física) a quem se
            referem os dados pessoais que são objeto do tratamento.
          </p>
          <p>
            <b>Consentimento:</b> Manifestação livre, informada e inequívoca
            pela qual o titular concorda com o tratamento de seus dados pessoais
            para uma finalidade determinada.
          </p>
          <p><b>1. Dados pessoais tratados pelo Site EBL Online </b></p>
          <p>1.1. O Site EBL Online poderá coletar seus dados pessoais:</p>
          <p>
            (i) por meio de cookies, quando Você navega no site, conforme
            explicado em nosso Aviso de
            <a href="https://eblonline.com.br/politica-de-cookies">Cookies;</a>
          </p>
          <p>
            (ii) caso Você os forneça por meio da realização do seu cadastro
            completo (nome, CPF, RG, e-mail, CNPJ, número de telefone celular,
            número de telefone fixo e nome da sua mãe);
          </p>
          <p>
            (iii) caso Você não tenha realizado um cadastro completo no Site EBL
            Online, porém forneça seus dados pessoais para receber publicidade
            personalizada e e-mails sobre os próximos leilões e eventos a serem
            realizados no Site EBL Online (nome, e-mail e número de telefone
            celular);
          </p>
          <p>
            (iv) por meio de nossos canais de atendimento (chat, telefone e
            e-mail), a fim de que seja possível identificá-lo e atender a sua
            solicitação e/ou dúvida;
          </p>
          <p>
            (v) quando Você realiza login, por meio da coleta do seu endereço
            IP, e (vi) de outras fontes para fins de prevenção de fraude,
            validação da sua identidade e análise de risco de crédito.
          </p>
          <p>
            1.2. É importante destacar que Você não é obrigado a nos fornecer
            seus dados pessoais, no entanto, caso Você não forneça seus dados
            pessoais não será possível utilizar os serviços disponíveis no Site
            EBL Online ou a capacidade do Site EBL Online fornecer serviços a
            Você poderá ser significativamente prejudicada, podendo, inclusive,
            ocasionar a suspensão dos serviços prestados a Você.
          </p>
          <p>2. Para quais finalidades utilizamos os seus dados pessoais?</p>
          <p>
            2.1. A WEBZI realizará o tratamento de seus dados pessoais para as
            finalidades abaixo descritas, conforme a base legal prevista no
            inciso V, do artigo 7º, da LGPD (execução de contrato ou de
            procedimentos preliminares relacionados ao contrato):
          </p>
          <p>
            2.1.1. Registrar Você em nossos sistemas para que possamos fornecer
            os serviços disponíveis no Site EBL Online;
          </p>
          <p>
            2.1.2. Identificar Você e contatá-lo a respeito de um leilão ou
            qualquer outro evento que Você queira participar ou tenha
            participado;
          </p>
          <p>
            2.1.3. Atender Você por meio de nossos canais de atendimento, caso
            seja necessário solucionar dúvidas e/ou atender suas solicitações
            sobre eventos que tenha participado ou se habilitado;
          </p>
          <p>
            2.1.4. Prestar serviços e fornecer produtos, conforme disposto nos
            Termos de Uso do Site EBL Online :
            <a href="https://www.eblonline.com.br/termos-de-uso">https://www.eblonline.com.br/termos-de-uso</a>;
          </p>
          <p>
            2.1.5. Permitir que a transação de venda e compra do bem
            anunciado/divulgado no Site EBL Online seja concluída;
          </p>
          <p>
            2.2. Para as finalidades abaixo, o Site EBL Online utilizará seus
            dados pessoais, conforme a base legal prevista no inciso IX, do
            artigo 7º, da LGPD (legítimo interesse):
          </p>
          <p>
            2.2.1. Validar e autenticar sua identidade, desde que Você tenha
            realizado um cadastro completo no Site EBL Online;
          </p>
          <p>2.2.2. Consultar birôs de crédito;</p>
          <p>
            2.2.3. Fornecer a Você informações relevantes sobre os serviços
            contratados; 2.2.4. Criar um ambiente seguro e confiável para os
            usuários do Site EBL Online;
          </p>
          <p>
            2.2.5. Prevenir e detectar fraudes ou quaisquer incidentes de
            segurança;
          </p>
          <p>
            2.2.6. Desenvolver estudos internos sobre seus interesses e
            comportamentos, a fim de que seja possível oferecer melhores
            serviços e produtos, inclusive com a realização de estudos
            estatísticos;
          </p>
          <p>
            2.2.7. Aprimorar nossas iniciativas comerciais e promocionais com o
            objetivo de oferecer serviços mais personalizados a Você;
          </p>
          <p>
            2.2.8. Enviar comunicações e publicidade relativas aos nossos
            serviços por diferentes canais, tais como: e-mail, whatsapp, sms,
            telefone e redes sociais, quando Você já é nosso cliente;
          </p>
          <p>2.2.9. Apoiar e promover as atividades do Site EBL Online;</p>
          <p>
            2.2.10. Realizar a cobrança judicial e/ou extrajudicial de multas
            previstas nas “Condições de Venda” de leilões e outros eventos
            realizados no Site EBL Online que, eventualmente, não tenham sido
            pagas por Você;
          </p>
          <p>
            2.2.11. Defender e/ou reivindicar os direitos do Site EBL Online em
            processos administrativos e/ou judiciais de qualquer natureza;
          </p>
          <p>
            2.2.12. Permitir que os usuários do Site EBL Online possam
            reivindicar seus direitos;
          </p>
          <p>
            2.2.13. Facilitar o processo de resolução de eventuais conflitos
            entre as empresas vendedoras de bens e os compradores;
          </p>
          <p>2.2.14. Realizar auditoria, e</p>
          <p>2.2.15. Realizar avaliações de risco.</p>
          <p>
            2.3. O Site EBL Online utilizará seus dados pessoais para que sejam
            cumpridas obrigações legais e/ou regulatórias as quais esteja
            sujeito. Assim, poderá armazenar e/ou transmitir dados pessoais ao
            órgão ou autoridade que a legislação aplicável determinar, nos
            termos do inciso II, do artigo 7º, da LGPD, em especial:
          </p>
          <p>
            2.3.1. Para prestar informações e/ou fornecer documentos à Junta
            Comercial do Estado do Rio de Janeiro – JUCERJ;
          </p>
          <p>
            2.3.2. Para cumprir normas de Prevenção à Lavagem de Dinheiro e
            Financiamento ao Terrorismo;
          </p>
          <p>
            2.3.3. Para verificar a identidade de Pessoas Politicamente
            Expostas,
          </p>
          <p>
            2.3.4. Para verificar histórico de transações, em conformidade com a
            regulamentação aplicável sobre prevenção à lavagem de dinheiro;
          </p>
          <p>
            2.3.5. Para permitir registros de crédito no Sistema de Informações
            de Crédito do Banco Central – (SCR);
          </p>
          <p>
            2.3.6. Para cumprir as obrigações impostas nos regimes tributários
            federal, estadual e municipal;
          </p>
          <p>
            2.3.7. Para cumprir ordens judiciais e/ou ofícios expedidos pelo
            Poder Judiciário, e
          </p>
          <p>
            2.3.8. Para cumprir ordens expedidas por órgãos administrativos do
            Poder Público, tais como Receita Federal, Secretarias da Fazenda e
            Detran.
          </p>
          <p>
            2.4. Para as finalidades abaixo, o Site EBL Online utilizará seus
            dados pessoais, desde que mediante o seu consentimento prévio,
            conforme a base legal prevista no inciso I, do artigo 7º, da LGPD:
          </p>
          <p>
            2.4.1. Enviar e-mails que podem informar sobre os próximos leilões,
            eventos, novos serviços ou sobre novidades do Site EBL Online. Esta
            finalidade é aplicável quando você nos forneceu nome, e-mail e
            número de telefone celular, mas ainda não realizou seu cadastro
            completo no Site EBL Online;
          </p>
          <p>
            2.4.2. Enviar publicidade personalizada a Você através do Facebook e
            do Instagram, quando Você ainda não tenha realizado o cadastro
            completo no Site EBL Online;
          </p>
          <p>
            2.4.2.1. É importante destacar que o Facebook e o Instagram são
            redes sociais que têm políticas de privacidade próprias, as quais
            Você deverá estar ciente;
          </p>
          <p>
            2.4.3. O consentimento dado por Você para as finalidades descritas
            nos itens 2.4.1 e 2.4.2, acima, poderá ser retirado, a qualquer
            momento, por meio do próprio e-mail que Você recebeu, bastando que
            Você localize no final do e-mail “Para não receber mais esse e-mail,
            clique aqui” ou através de e-mail a ser enviado para:
            privacidade@eblonline.com.br, o qual deverá informar sobre sua
            intenção de revogar o consentimento dado, e 2.4.3.1. Ao revogar seu
            consentimento e optar por não receber mais e-mails, Você está ciente
            de que não direcionaremos mais publicidade personalizada através do
            Facebook e Instagram, porém, eventuais anúncios ainda poderão ser
            exibidos a Você, tendo em vista que o Facebook e o Instagram são
            redes sociais que têm políticas de privacidade próprias, as quais
            não estão sob controle do Site EBL Online
          </p>
          <p><b>3. Como compartilhamos seus dados pessoais? </b></p>
          <p>
            3.1. Site EBL Online poderá compartilhar seus dados pessoais com:
            (i) órgãos públicos e/ou autoridades em decorrência de obrigações
            legais; (ii) as empresas que são contratadas para viabilizar todos
            os serviços disponibilizados a Você no Site EBL Online; (iii) as
            empresas vendedoras dos bens que Você comprou através do Site EBL
            Online, e (iv) o Leiloeiro Oficial que realizou o leilão publico que
            Você participou.
          </p>
          <p>
            3.2. Todas as contratações de empresas que sejam necessárias para
            viabilizar os serviços disponibilizados no Site EBL Online são
            realizadas através de instrumentos contratuais que contêm cláusulas
            específicas de privacidade e proteção de dados, a fim de assegurar
            que seus dados pessoais sejam utilizados apenas para finalidades
            específicas e que sejam mantidos segurança. Este cuidado se revela
            ainda mais forte nos casos em que estas empresas forem consideradas
            como Operadoras de dados pessoais.
          </p>
          <p>
            <b
              >4. Onde seus dados pessoais podem ficar armazenados e por quanto
              tempo são armazenados?
            </b>
          </p>
          <p>
            4.1. Os dados pessoais que Você forneceu ao se cadastrar no Site EBL
            Online ficarão armazenados em data center localizado no Brasil,
            porém em algumas situações poderão ficar armazenados nos Estados
            Unidos da América, tendo em vista que a WEBZI utiliza provedor de
            e-mail e serviços de computação em nuvem das empresas Google e
            Amazon Web Services.
          </p>
          <p>
            4.2. É importante destacar que as empresas mencionadas no item 4.1,
            acima, adotam avançadas técnicas para garantir a proteção de seus
            dados pessoais.
          </p>
          <p>
            4.3. Seus dados pessoais serão armazenados pelo tempo que for
            necessário para cumprir com as finalidades para as quais foram
            coletados ou pelo prazo que for determinado na legislação aplicável.
          </p>
          <p>
            4.4. Caso Você seja um cliente do Site EBL Online, deverá estar
            ciente de que seus dados pessoais ficarão armazenados por pelo menos
            05 (cinco) anos a contar da sua última compra ou participação em um
            leilão, tendo em vista obrigações impostas pela legislação
            tributária, legislação relacionada aos leilões públicos e/ou
            regulamentação do Banco Central do Brasil.
          </p>
          <p><b>5. Proteção de Dados Pessoais </b></p>
          <p>
            5.1. O Site EBL Online está comprometido em adotar as melhores
            práticas e a tomar todas as medidas razoáveis para proteger seus
            dados pessoais contra acesso não autorizado, alteração acidental ou
            ilícita, divulgação não autorizada ou destruição de seus dados
            pessoais.
          </p>
          <p>
            5.2. Para garantir a sua privacidade e a proteção dos seus dados
            pessoais, adotamos recursos tecnológicos avançados para garantir a
            segurança de todos os dados tratados pelo Site EBL Online. Entre as
            medidas de segurança implementadas estão a implementação de
            controles de acesso a sistemas e ambientes de tratamento de dados,
            técnicas de criptografia e a instalação de barreiras contra o acesso
            indevido às bases de dados (incluindo firewalls), entre outros
            controles de segurança da informação.
          </p>
          <p>
            5.3. O Site EBL Online se esforça para proteger a privacidade de
            seus dados pessoais, porém não podemos garantir total segurança.
            Usos não autorizados de seus dados pessoais por terceiros, falhas de
            hardware ou software que não estejam sob controle do Site EBL Online
            e outros fatores externos podem comprometer a segurança dos seus
            dados pessoais. Por isso, sua atuação é fundamental para a
            manutenção de um ambiente seguro. Caso você identifique ou tome
            conhecimento de qualquer fator que comprometa a segurança dos seus
            dados na sua relação com o Site EBL Online, por favor, entre em
            contato conosco por meio do e-mail: privacidade@eblonline.com.br.
          </p>
          <p><b>6. Direitos dos Titulares de Dados Pessoais</b></p>
          <p>
            6.1. Como titular de dados pessoais, Você poderá exercer os direitos
            previstos na LGPD, conforme abaixo:
          </p>
          <p>
            6.1.1. Confirmação da existência de tratamento: permite que Você
            possa requisitar e receber uma confirmação sobre a existência do
            tratamento de seus dados pessoais;
          </p>
          <p>
            6.1.2. Acesso aos dados pessoais: permite que Você requisite e
            receba uma cópia dos dados pessoais que porventura sejam tratados
            pelo Site EBL Online;
          </p>
          <p>
            6.1.3. Correção de dados incompletos, inexatos ou desatualizados:
            caso Você identifique alguma incorreção ou imprecisão nos seus dados
            pessoais, poderá realizar login no Site EBL Online para alterá-los a
            qualquer momento ou entrar em contrato com a nossa Central de
            Atendimento;
          </p>
          <p>
            6.1.4. Anonimização, bloqueio ou eliminação dos dados pessoais
            desnecessários, excessivos ou tratados em desconformidade com a
            legislação: permite que seja requerida a anonimização, o bloqueio ou
            a eliminação dos dados pessoais da nossa base. Todos os dados
            pessoais coletados serão anonimizados, bloqueados ou eliminados dos
            nossos servidores caso seja possível e requisitado por Você.
          </p>
          <p>
            6.1.5. Portabilidade: obter acesso aos dados pessoais que são
            tratados pelo Site EBL Online em formato estruturado e
            interoperável;
          </p>
          <p>
            6.1.6. Revogar ou não fornecer o consentimento: Você tem o direito
            de revogar o seu consentimento ou, se for o caso, de não nos
            fornecê-lo e ser informado sobre as consequências da negativa. Em
            alguns casos, é possível que da negativa decorra a impossibilidade
            de lhe prover determinados produtos ou serviços;
          </p>
          <p>
            6.1.7. Revisão de decisões automatizadas: Você tem o direito de
            solicitar a revisão de decisões tomadas unicamente com base em
            tratamento automatizado dos dados pessoais e de receber informações
            claras e adequadas a respeito dos critérios utilizados na decisão,
            desde que essas informações não violem qualquer segredo comercial do
            Site EBL Online;
          </p>
          <p>
            6.1.8. Oposição ao tratamento: Você também tem o direito de se opor
            a determinadas finalidades de tratamento de dados pessoais, como,
            por exemplo, o envio de publicidade, newsletter ou novas ofertas, e
          </p>
          <p>
            6.1.9. Eliminação dos dados pessoais tratados com consentimento:
            caso Você tenha dado o seu consentimento para uma finalidade de
            tratamento, Você pode requisitar a eliminação dos dados pessoais que
            armazenamos até então.
          </p>
          <p>
            6.2. Você poderá exercer os direitos previstos acima, mediante
            solicitação para o seguinte e-mail: privacidade@eblonline.com.br ou
            por meio de login no Site EBL Online
          </p>
          <p>
            6.3. Site EBL Online poderá solicitar informações específicas para
            confirmar sua identidade e garantir a possibilidade de que Você
            possa exercer os seus Direitos. Esta é uma medida de segurança para
            assegurar que os seus dados não sejam divulgados a qualquer pessoa
            que não tenha legitimidade para recebê-los.
          </p>
          <p>
            6.4. Os seus direitos como titular de dados pessoais não são
            ilimitados e, por isso, apresentamos abaixo algumas situações que
            podem levar à negativa total ou parcial deles: (i) preservação de
            segredo de negócio e propriedade intelectual do Site EBL Online;
            (ii) violação de direitos e liberdades de terceiros; (iii) as
            informações estão anonimizadas e, portanto, não são dados pessoais;
            (iv) obstrução à lei e justiça; (v) os interesses legítimos do Site
            EBL Online sobrepõem-se aos do titular dos dados pessoais; (vi) o
            titular fez requisições repetitivas, reiteradas ou excessivas, e
            (vii) o pedido não pode ser atendido, pois contraria Leis ou
            regulamentos aplicáveis ao Site EBL Online.
          </p>
          <p><b>7. Dúvidas e Contato</b></p>
          <p>
            Caso seja necessário realizar alguma solicitação relativa a este
            Aviso de Privacidade, Você poderá entrar em contato com o
            Encarregado pela Proteção de Dados Pessoais (DPO), através do
            e-mail: privacidade@eblonline.com.br
          </p>
          <p><b>8. Alterações no Aviso de Privacidade</b></p>
          <p>
            Este Aviso de Privacidade poderá sofrer atualizações e ajustes a
            qualquer momento, a fim de refletir as práticas e os deveres do Site
            EBL Online em matéria de privacidade e proteção de dados pessoais.
            Caso as alterações sejam relevantes, o Site EBL Online poderá
            utilizar os canais disponíveis para avisar Você.
          </p>
          <p>Data da última atualização: 14 de fevereiro de 2022.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>
